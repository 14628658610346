<template>

    <div class="sidebar">

        <div class="sidebar_menu">

            <router-link class="sidebar_element"
                :class="{active : this.$route.name == 'Home'}"
                to="/home">

                <i class="ri-home-6-line"></i>
                <span>Home</span>

            </router-link>

            <router-link class="sidebar_element"
                :class="{active : this.$route.name == 'Ads'}"
                to="/ads">

                <i class="ri-advertisement-fill"></i>
                <span>Ads</span>

            </router-link>

            <router-link class="sidebar_element"
                :class="{active : this.$route.name == 'Script'}"
                to="/script">

                <i class="ri-code-s-slash-line"></i>
                <span>Script</span>

            </router-link>

            <router-link class="sidebar_element"
                :class="{active : this.$route.name == 'Calendario'}"
                to="/calendar">

                <i class="ri-calendar-check-fill"></i>
                <span>Calendar</span>

            </router-link>

            <router-link class="sidebar_element"
                :class="{active : this.$route.name == 'Reports'}"
                to="/reports">

                <i class="ri-pie-chart-2-line"></i>
                <span>Analytics</span>

            </router-link>

            <!-- <router-link class="sidebar_element"
                :class="{active : this.$route.name == 'Reports'}"
                to="/preview">

                <i class="ri-slideshow-3-line"></i>
                <span>Preview / test</span>

            </router-link> -->



            <router-link class="sidebar_element"
                :class="{active : this.$route.name == 'Account'}"
                to="/account">

                <i class="ri-user-6-line"></i>
                <span>Account</span>

            </router-link>

        </div>

        <div class="sidebar_bottom">

        </div>

    </div>

</template>

<script>

export default {
    name: 'SideBar'
}

</script>
