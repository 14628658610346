import { createStore } from 'vuex'

export default createStore({
  state: {
      user: {},
    //   months: ['gen', 'feb', 'mar', 'apr', 'mag', 'giu', 'lug', 'ago', 'set', 'ott', 'nov', 'dic']
      months: ['gen', 'feb', 'mar', 'apr', 'may', 'jun', 'jul', 'aug', 'sep', 'oct', 'nov', 'dec']
  },
  mutations: {
      loginUser(state, loggedUser) {
          state.user = loggedUser
      }
  },
  actions: {
  },
  modules: {
  }
})
