<template>

</template>

<script>

export default {
    name: 'SuperAdmin',

    data() {
        return {

        }
    }


}

</script>
