<script setup>
import LoginForm from '@/components/LoginForm.vue'
</script>

<template>

    <h1>Account</h1>

    <LoginForm/>

</template>

<script>


    export default {
        name: 'Account',
    }

</script>
