<script setup>
import PreviewSpace from '@/components/PreviewSpace.vue'
</script>

<template>

    <div class="add_row" @click="openEditAdv()">
        <span>Add new ADV</span>
    </div>

    <div v-if="openEdit" class="popup_layer" :class="{hide: !openEdit}">

        <div class="popup_editor" :class="{hide: !openEdit}">

            <div class="editor_form">

                <div class="form_header">

                    <i class="ri-file-add-line"></i>

                    <h2>Edit Adv</h2>

                </div>

                <div class="form_content">

                    <div class="formblock">

                        <h3>Titolo</h3>

                        <input type="text" name="" v-model="editAdv.name">

                    </div>

                    <div class="formblock">

                        <h3>Publishing</h3>

                        <div class="status_row">
                            <div class="">
                                <div class="button main"
                                    v-if="!editAdv.active"
                                    @click="changeStatus('edit')">

                                    <i class="ri-play-fill"></i>
                                    <span>Publish</span>

                                </div>

                                <div class="button main"
                                    v-if="editAdv.active"
                                    @click="changeStatus('edit')">

                                    <i class="ri-play-fill"></i>
                                    <span>Unpublish</span>

                                </div>

                            </div>

                            <div class="status">
                                <span>Status: </span>
                                <span :class="{active: editAdv.active}">{{editAdv.active ? 'Active' : 'Inactive'}}</span>
                            </div>

                        </div>

                        <div class="date_row">

                            <div class="">
                                <p>Start:</p>
                                <input type="date" name="" v-model="editAdv.schedule.dateStart">
                            </div>

                            <div class="">
                                <p>End:</p>
                                <input type="date" name="" v-model="editAdv.schedule.dateEnd">
                            </div>

                        </div>

                    </div>

                    <div class="formblock">

                        <h3>Button</h3>

                        <p>Button Text</p>

                        <input type="text" name="" v-model="editAdv.textButton">

                        <p>Button Url</p>

                        <input type="url" name="" v-model="editAdv.urlLink">

                    </div>

                    <div class="formblock">

                        <h3>Seconds before skip</h3>

                        <div class="row_skip">

                            <p>Seconds to wait before skipping</p>

                            <input type="number" name="" min="0" v-model="editAdv.skipSeconds">

                        </div>

                    </div>

                    <div class="formblock">

                        <h3>Image</h3>

                        <div class="media_upload">

                            <div class="instructions">

                                <p class="strong">Instructions</p>
                                <p>File extensions: .jpg, .jpeg, .png</p>
                                <p>Max size (suggested): 680x680 px</p>
                                <p>Max file size: 350KB</p>
                                <br>
                                <p>The chosen image will also be the video preview.</p>

                            </div>

                            <div class="upload">

                                <img class="preview_img" v-if="previewImageUrl" :src="previewImageUrl" :key="previewKey" alt="">

                                <div v-if="previewImageUrl"
                                    class="delete_media"
                                    @click="deleteMedia('image')">

                                    <i class="ri-delete-bin-line"></i>

                                </div>

                                <input type="file" name="" @change="previewMedia('image')">

                            </div>

                        </div>

                    </div>

                    <div class="formblock">

                        <h3>Video</h3>

                        <div class="media_upload">

                            <div class="instructions">

                                <p class="strong">Istruzioni</p>
                                <p>File extension: .mp4</p>
                                <p>Max size (suggested): 680x680 px</p>
                                <p>Max file size: 2MB</p>

                            </div>

                            <div class="upload">

                                <video class="preview_img" v-if="previewVideoUrl" :key="previewKey" controls>

                                    <source :src="previewVideoUrl" type="video/mp4">

                                </video>

                                <div v-if="previewVideoUrl"
                                    class="delete_media"
                                    @click="deleteMedia('video')">

                                    <i class="ri-delete-bin-line"></i>

                                </div>

                                <input type="file" name="" @change="previewMedia('video')">

                            </div>

                        </div>


                    </div>

                    <div class="formblock">

                        <h3>Style</h3>

                        <div class="row_color">
                            <input class="colorpicker" type="color" v-model="editAdv.style.backgroundColor">
                            <input type="text" name="" v-model="editAdv.style.backgroundColor">
                            <span>Background color</span>
                        </div>

                        <div class="row_color">
                            <input class="colorpicker" type="color" v-model="editAdv.style.textColor">
                            <input type="text" name="" v-model="editAdv.style.textColor">
                            <span>Text color</span>
                        </div>

                        <div class="row_color">
                            <input class="colorpicker" type="color" v-model="editAdv.style.buttonColor">
                            <input type="text" name="" v-model="editAdv.style.buttonColor">
                            <span>Button Background color</span>
                        </div>

                        <div class="row_color">
                            <input class="colorpicker" type="color" v-model="editAdv.style.buttonText">
                            <input type="text" name="" v-model="editAdv.style.buttonText">
                            <span>Button text color</span>
                        </div>

                    </div>

                </div>

                <div class="form_bottom">

                    <div v-if="uploadloader" class="uploadloader">
                        <div class="loader" v-if="loaderImage > 0">
                            <span>Image upload: {{loaderImage}}%</span>
                            <div class="loadbar" :style="'width:' + loaderImage + '%'"></div>
                        </div>

                        <div class="loader"  v-if="loaderVideo > 0">
                            <span>Video upload: {{loaderVideo}}%</span>
                            <div class="loadbar" :style="'width:' + loaderVideo + '%'"></div>
                        </div>
                    </div>

                    <div class="button_grid">

                        <div class="button main"
                            :class="{disabled: !isReadyToSave}"
                            @click="saveEditAdv(editAdv.id)">

                            <i class="ri-check-line"></i>

                            <span>Save</span>

                        </div>

                        <div class="button dismiss"
                            @click="closeEditAdv()">

                            <i class="ri-close-line"></i>

                            <span>Dismiss</span>

                        </div>

                    </div>

                </div>

            </div>

            <PreviewSpace :adv="editAdv" :imageurl="previewImageUrl" :videourl="previewVideoUrl" :previewstyle="editAdv.style" :key="previewKey" />

        </div>
        <!-- FINE AGGIUNGI NUOVO ADV -->

    </div>




    <div v-for="adv in ads" :key="adv.id" class="adv_row">

        <div class="adv_info image">
            <img class="img_small" :src="adv.image.imageUrl" alt="adv.name">
        </div>

        <div class="adv_info name">
            <div class="row_label">
                <i class="ri-input-cursor-move"></i>
                <span>Title</span>
            </div>
            <span class="info_content">{{adv.name}}</span>
        </div>

        <div class="adv_info status">
            <div class="row_label">
                <i class="ri-slideshow-4-line"></i>
                <span>Status</span>
            </div>
            <span class="info_content" :class="{active: adv.active}">
                {{adv.active ? 'Active' : 'Inactive'}}
            </span>
        </div>

        <div class="adv_info">
            <div class="row_label">
                <i class="ri-calendar-event-line"></i>
                <span>Start</span>
            </div>

            <span class="info_content">{{formatDate(adv.schedule.dateStart)}}</span>

        </div>

        <div class="adv_info">
            <div class="row_label">
                <i class="ri-calendar-event-line"></i>
                <span>End</span>
            </div>

            <span class="info_content">{{formatDate(adv.schedule.dateEnd)}}</span>

        </div>

        <!-- <div class="adv_info">
            <div class="row_label">
                <i class="ri-calendar-event-line"></i>
                <span>Fine</span>
            </div>
            <span class="info_content">{{formatDate(adv.schedule.dateEnd)}}</span>
        </div> -->

        <!-- <div class="adv_info stats">
            <div class="row_label">
                <i class="ri-bar-chart-box-line"></i>
                <span>Views</span>
            </div>

            <div class="">
                <span class="info_content">Totali: </span>
                <span class="info_content">{{adv.views}}</span>
            </div>

            <div class="">
                <span class="info_content">Unici: </span>
                <span class="info_content">{{adv.uniqueViews}}</span>
            </div>

        </div> -->

        <!-- <div class="adv_info stats">
            <div class="row_label">
                <i class="ri-bar-chart-box-line"></i>
                <span>Click</span>
            </div>
            <div class="">
                <span class="info_content">Totali: </span>
                <span class="info_content">{{adv.clicks}}</span>
            </div>

            <div class="">
                <span class="info_content">Unici: </span>
                <span class="info_content">{{adv.uniqueClicks}}</span>
            </div>
        </div> -->

        <div class="adv_edit">

            <div class="button small main" @click="openEditAdv(adv)">
                <i class="ri-edit-line"></i>
                <span>Edit</span>
            </div>

            <div class="button small delete" @click="deleteAdv(adv)">
                <i class="ri-delete-bin-line"></i>
                <span>Delete</span>
            </div>

        </div>

    </div>

</template>


<script>

import { db, storage } from '@/services/firebaseDb.js';
import { query, where, collection, getDoc, getDocs, addDoc, doc, setDoc, onSnapshot, deleteDoc, updateDoc} from 'firebase/firestore';
import {ref, deleteObject, uploadBytesResumable, getDownloadURL} from 'firebase/storage';


export default {
    name: 'AdsList',

    data() {
        return {
            openEdit: false,
            ads: [],
            editAdv: {},
            mediaToUpload: {},

            uploadloader: false,

            previewImageUrl: null,
            previewImageRef: null,
            loaderImage: 0,

            previewVideoUrl: null,
            previewVideoRef: null,
            loaderVideo: 0,

            previewKey: 0
        }
    },

    computed: {
        isReadyToSave() {
            if (this.editAdv.name && this.editAdv.textButton && this.editAdv.urlLink && this.previewImageUrl) {
                return true
            }
        }
    },

    watch: {
        editAdv() {
            this.previewKey += 1
        },
        previewImageUrl() {
            this.previewKey += 1
        },
        previewVideoUrl() {
            this.previewKey += 1
        },

    },

    mounted() {
        setTimeout(()=>{
            this.loadData()
        },500)
        setTimeout(()=>{
            this.loadStats()
        },100)
    },

    methods: {
        formatDate(value) {
            let y = value.split('-')[0]
            let m = value.split('-')[1]
            let d = value.split('-')[2]

            return d + ' ' + this.$store.state.months[Number(m)-1] + ' ' + y.slice(-2)
        },

        changeStatus(value) {
            if(value == 'new') {
                if (!this.newAdv.active) {
                    this.newAdv.active = true
                } else {
                    this.newAdv.active = !this.newAdv.active
                }
            }
            if(value == 'edit') {
                if (!this.editAdv.active) {
                    this.editAdv.active = true
                } else {
                    this.editAdv.active = !this.editAdv.active
                }
            }
        },

        

        async loadData() {
            this.ads = []

            let uid = this.$store.state.user.uid
            let adsRef = collection(db, 'companies/' + uid + '/ads')


            const querySnapshot = await getDocs(adsRef)


            querySnapshot.forEach(item => {

                let adv = {...item.data()}
                adv.id = item.id

                if(!this.ads.some(ad => ad.id == adv.id)) {
                    this.ads.push(adv)
                }

            });

        },

        previewMedia(value) {
            let file = event.target.files[0]
            let fileurl = URL.createObjectURL(file)

            if (value == 'image') {
                this.imageToUpload = file
                this.previewImageUrl = fileurl
            }

            if (value == 'video') {
                this.videoToUpload = file
                this.previewVideoUrl = fileurl
            }
        },

        deleteMedia(kind) {

            if(kind == 'image') {
                this.previewImageUrl = null
            }

            if(kind == 'video') {
                this.previewVideoUrl = null
            }
        },

        openEditAdv(adv) {
            this.previewImageUrl = null
            this.previewVideoUrl = null
            this.oldImageRef = null
            this.oldVideoRef = null

            if (adv) {
                this.editAdv = {...adv}
                this.previewImageUrl = this.editAdv.image.imageUrl
                this.previewVideoUrl = this.editAdv.video.videoUrl
                this.oldImageRef = this.editAdv.image.imageRef
                this.oldVideoRef = this.editAdv.video.videoRef
            }
            else {
                let defaultDate = new Date()
                let thisDay = defaultDate.toISOString().substring(0, 10);
                this.editAdv = {
                    name: '',
                    active: false,
                    textButton: '',
                    urlLink: '',
                    skipSeconds: '10',
                    image:{
                        imageUrl: '',
                        imageRef: ''
                    },
                    video:{
                        videoUrl: '',
                        videoRef: ''
                    },
                    style: {
                        backgroundColor: '#2d2e2f',
                        textColor: '#ffffff',
                        buttonColor: '#ffffff',
                        buttonText: '#2d2e2f'
                    },
                    schedule: {
                        dateStart: thisDay,
                        dateEnd: thisDay,
                    }
                }
            }

            this.openEdit = true
        },

        closeEditAdv() {
            this.openEdit = false
            this.editAdv = {}
        },


        async saveEditAdv(advId) {
            let uid = this.$store.state.user.uid

            let isNew = false

            let mediaId = advId ? advId : Date.now()

            if (!advId) {
                isNew = true
            }

            const uploadImage = new Promise((resolve, reject) => {

                    if (this.imageToUpload) {

                        this.loaderImage = 0
                        this.uploadloader = true

                        if(this.oldImageRef) {
                            deleteObject(ref(storage, this.oldImageRef))
                        }

                        let refPath = 'images/' + uid + '/' + mediaId
                        let mediaRef = ref(storage, refPath)

                        let uploadTask = uploadBytesResumable(mediaRef, this.imageToUpload)

                        uploadTask.on('state_changed',
                            (snapshot) => {
                                const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                                this.loaderImage = progress.toFixed(1)
                            },
                            (error) => {
                                // Handle unsuccessful uploads
                            },
                            () => {
                                getDownloadURL(uploadTask.snapshot.ref)
                                .then((downloadUrl) => {
                                    this.editAdv.image.imageUrl = downloadUrl
                                    this.editAdv.image.imageRef = refPath
                                    resolve()
                                })
                            }
                        )
                    }
                    else {
                        resolve()
                    }
            })


            const uploadVideo =  new Promise( (resolve, reject)=>{

                    if (this.videoToUpload) {

                        this.loaderImage = 0
                        this.uploadloader = true

                        if(this.oldVideoRef) {
                            deleteObject(ref(storage, this.oldVideoRef))
                        }

                        let refPath = 'video/' + uid + '/' + mediaId
                        let mediaRef = ref(storage, refPath)

                        let uploadTask = uploadBytesResumable(mediaRef, this.videoToUpload)

                        uploadTask.on('state_changed',
                            (snapshot) => {
                                const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                                this.loaderVideo = progress.toFixed(1)
                            },
                            (error) => {
                                // Handle unsuccessful uploads
                            },
                            () => {
                                getDownloadURL(uploadTask.snapshot.ref)
                                .then((downloadUrl) => {
                                    this.editAdv.video.videoUrl = downloadUrl
                                    this.editAdv.video.videoRef = refPath
                                    this.loaderVideo = 0
                                    resolve()
                                })
                            }
                        )
                    }
                    else {
                        resolve()
                    }
            })


            await Promise.all([uploadImage, uploadVideo ]).then(()=>{

                if (isNew) {

                    let adsRef = collection(db, 'companies/' + uid + '/ads')

                    let newAdv = {
                        active: this.editAdv.active ? true : false,
                        name: this.editAdv.name,
                        textButton: this.editAdv.textButton,
                        urlLink: this.editAdv.urlLink,
                        skipSeconds: this.editAdv.skipSeconds,
                        image: {
                            imageRef: this.editAdv.image.imageRef,
                            imageUrl: this.editAdv.image.imageUrl,
                        },
                        video: {
                            videoRef: this.editAdv.video.videoRef,
                            videoUrl: this.editAdv.video.videoUrl,
                        },
                        style: {
                            backgroundColor: this.editAdv.style.backgroundColor,
                            textColor: this.editAdv.style.textColor,
                            buttonColor: this.editAdv.style.buttonColor,
                            buttonText: this.editAdv.style.buttonText
                        },
                        schedule: {
                            dateStart: this.editAdv.schedule.dateStart,
                            dateEnd: this.editAdv.schedule.dateEnd,
                        }
                    }

                    let newAdvId = new Date().getTime()
                    console.log(newAdvId)

                    const newAdvRef = (async () => {
                        await setDoc(doc(db, 'companies/' + uid + '/ads', newAdvId.toString()), newAdv);
                        // await setDoc(doc(collection(db, 'companies/' + uid + '/ads')), newAdv)
                    })

                    newAdvRef()
                    .then(()=>{
                        this.uploadloader = false
                        this.openEdit = false
                        this.loadData()
                        console.log('salvato')
                    })
                    .then(()=>{
                        this.editAdv = {}
                        this.previewImageUrl = null
                        this.previewVideoUrl = null
                        this.imageToUpload = null
                        this.videoToUpload = null
                    })

                    let newAnal = {
                        clicks: 0,
                        uniqueClicks: 0,
                        views: 0,
                        uniqueViews: 0
                    }

                    const newAdvAnal = (async () => {
                         await setDoc(doc(db, 'analytics/', newAdvId.toString()), newAnal);
                    })
                    newAdvAnal()
                }

                else {

                    const advRef = doc(db, 'companies/' + uid + '/ads', mediaId)

                    const updateAdvRef = (async () => {
                        await updateDoc(advRef, {
                            active: this.editAdv.active ,
                            name: this.editAdv.name,
                            textButton: this.editAdv.textButton,
                            urlLink: this.editAdv.urlLink,
                            image: {
                                imageRef: this.editAdv.image.imageRef ? this.editAdv.image.imageRef : null,
                                imageUrl: this.editAdv.image.imageUrl ? this.editAdv.image.imageUrl : null,
                            },
                            video: {
                                videoRef: this.editAdv.video.videoRef ? this.editAdv.video.videoRef : null,
                                videoUrl: this.editAdv.video.videoUrl ? this.editAdv.video.videoUrl : null
                            },
                            style: {
                                backgroundColor: this.editAdv.style.backgroundColor,
                                textColor: this.editAdv.style.textColor,
                                buttonColor: this.editAdv.style.buttonColor,
                                buttonText: this.editAdv.style.buttonText
                            },
                            schedule: {
                                dateStart: this.editAdv.schedule.dateStart,
                                dateEnd: this.editAdv.schedule.dateEnd,
                            }
                        })
                    })

                    updateAdvRef()
                    .then(()=>{
                        this.uploadloader = false
                        this.openEdit = false
                        this.loadData()
                        console.log('modificato')
                    })
                    .then(()=>{
                        this.editAdv = {}
                        this.preloadedImage = {}
                        this.preloadedVideo = {}
                    })
                }


            })
        },

        async deleteAdv(adv) {
            let uid = this.$store.state.user.uid

            const deleteAdvRef = async () => {
                await deleteDoc(doc(db, 'companies/' + uid + '/ads', adv.id))
            }

            deleteAdvRef()
            .then(()=>{
                this.loadData()

                if(adv.image.imageRef) {
                    let imageRef = ref(storage, adv.image.imageRef)
                    deleteObject(imageRef).then(()=>{
                        this.previewImageUrl = null
                        console.log(this.previewImageUrl)
                    })

                }
                if(adv.video.videoRef) {
                    let videoRef = ref(storage, adv.video.videoRef)
                    deleteObject(videoRef).then(()=>{
                        this.previewVideoUrl = null
                        console.log(this.previewVideoUrl)
                    })
                }


            })

        }


    }
}

</script>
