<script setup>
</script>

<template>

    <div class="calendar">

        <div class="calendar_header">

            <div class="calendar_controls">

                <div class="button small main" @click="changeMonth('prev')">
                    <i class="ri-arrow-left-s-line"></i>
                </div>

                <h3>{{activeMonthName}} {{activeYear}}</h3>

                <div class="button small main" @click="changeMonth('next')">
                    <i class="ri-arrow-right-s-line"></i>
                </div>

            </div>

        </div>

        <div class="days_header">

            <div v-for="day in days" :key="day" class="day" :data-fulldate="day.fullDate">
                <div class="dayColumn" :style="'height: ' + dayColumnHeight"></div>
                <p class="dayname">{{day.name}}</p>
                <p class="daynumber">{{day.number}}</p>
            </div>

        </div>


        <div v-if="calendarAds" v-for="adv in calendarAds" class="calendar_row">

            <div class="calendar_adv"
                :class="{startBefore: adv.startBefore, endAfter: adv.endAfter}"
                :style="'left:' + adv.left + 'px; width:' + adv.width + 'px;'"
                :data-start="adv.schedule.dateStart"
                :data-end="adv.schedule.dateEnd">

                <img class="calendar_preview" :src="adv.image.imageUrl" alt="">

                <div class="truncate">
                    <span>{{adv.name}}</span>
                </div>

            </div>


        </div>


    </div>

</template>

<script>
import { db, storage } from '@/services/firebaseDb.js';
import { query, where, collection, getDoc, getDocs, addDoc, doc, setDoc, onSnapshot, deleteDoc, updateDoc} from 'firebase/firestore';
import {ref, deleteObject, uploadBytesResumable, getDownloadURL} from 'firebase/storage';

export default {
    name: 'CalendarComp',

    data() {
        return {
            ads: [],
            calendarAds: [],
            // weekdays: [ 'D', 'L', 'M', 'M', 'G', 'V', 'S'],
            weekdays: [ 'S', 'M', 'T', 'W', 'T', 'F', 'S'],
            // months: ['Gennaio', 'Febbraio', 'Marzo', 'Aprile', 'Maggio', 'Giugno', 'Luglio', 'Agosto', 'Settembre', 'Ottobre', 'Novembre', 'Dicembre'],
            months: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'],
            date: new Date(),

            activeMonth: null,
            activeYear: null,
            today: null
        }
    },

    mounted() {

        this.activeMonth = this.date.getMonth() + 1,
        this.activeYear = this.date.getFullYear(),
        this.today = new Date().getDate()

        setTimeout(()=>{
            this.loadData()
        },300)

    },

    computed: {

        activeMonthName() {
            return this.months[this.activeMonth - 1]
        },

        daysInMonth() {
            return new Date(this.activeYear, this.activeMonth, 0).getDate()
        },

        dayColumnHeight() {
            return 'calc(100% + calc(' + this.calendarAds.length + ' * 4rem) + 4rem)'
        },

        days() {
            let days = []
            for (var i = 0; i < this.daysInMonth; i++) {
                let dayname = new Date(this.activeYear, this.activeMonth, i).getDay()

                let dateMonth = this.activeMonth > 9 ? this.activeMonth : '0' + this.activeMonth
                let calcDay = Number(i + 1)
                let dateDay = calcDay > 9 ? calcDay : '0' + calcDay

                let day = {
                    number: i + 1,
                    name: this.weekdays[dayname],
                    fullDate: this.activeYear + '-' + dateMonth + '-' + dateDay
                }

                days.push(day)
            }
            return days
        }
    },


    methods: {

        changeMonth(value) {
            this.calendarAds = []

            if (value == 'next') {
                if ((this.activeMonth + 1) > 11) {
                    this.activeMonth = 1
                    this.activeYear = this.activeYear + 1
                }
                else {
                    this.activeMonth = this.activeMonth + 1
                }
            }

            if (value == 'prev') {
                if ((this.activeMonth - 1) < 0) {
                    this.activeMonth = 11
                    this.activeYear = this.activeYear - 1
                }
                else {
                    this.activeMonth = this.activeMonth - 1
                }
            }

            setTimeout(()=>{
                this.loadCalendarAds()
            },300)

        },

        async loadData() {
            this.ads = []

            let uid = this.$store.state.user.uid
            let adsRef = collection(db, 'companies/' + uid + '/ads')

            const querySnapshot = await getDocs(adsRef)

            querySnapshot.forEach(item => {

                let adv = {...item.data()}
                adv.id = item.id

                if(!this.ads.some(ad => ad.id == adv.id)) {
                    this.ads.push(adv)
                }
            });


            setTimeout(()=>{
                this.loadCalendarAds()
            },300)

        },

        loadCalendarAds() {
            this.calendarAds = []

            this.ads.forEach((adv, i) => {

                let inCalendar = false

                let advStartMonth = adv.schedule.dateStart.split('-')[1]
                let advEndMonth = adv.schedule.dateEnd.split('-')[1]

                let advStartYear = adv.schedule.dateStart.split('-')[0]
                let advEndYear = adv.schedule.dateEnd.split('-')[0]

                let advStartDay = adv.schedule.dateStart.split('-')[2]
                let advEndDay = adv.schedule.dateEnd.split('-')[2]

                let dateMonth = this.activeMonth > 9 ? this.activeMonth : '0' + this.activeMonth
                console.log(dateMonth)
                let dateYear = this.activeYear

                let daysInMonth = this.daysInMonth

                let startRef = Number(advStartYear.toString() + advStartMonth.toString())
                let dateRef = Number(dateYear.toString() + dateMonth.toString())
                let endRef = Number(advEndYear.toString() + advEndMonth.toString())


                let monthBoundStart = Number(dateYear.toString() + dateMonth.toString() + '01')
                let monthBoundEnd = Number(dateYear.toString() + dateMonth.toString() + daysInMonth)

                let fullStartRef = Number(advStartYear.toString() + advStartMonth.toString() + advStartDay.toString())
                let fullEndRef = Number(advEndYear.toString() + advEndMonth.toString() + advEndDay.toString())


                if (((fullStartRef >= monthBoundStart) && (fullStartRef <= monthBoundEnd)) || ((fullEndRef >= monthBoundStart) && (fullEndRef <= monthBoundEnd))) {

                    inCalendar = true
                    this.calendarAds.push(adv)

                }

                if (inCalendar) {

                    let dayElemStart
                    let dayElemEnd

                    adv.left = 0
                    adv.width = 0

                    adv.startBefore = false
                    adv.endAfter = false

                    // console.log(startRef, dateRef, endRef)

                    dayElemStart = document.querySelector('[data-fulldate="' + adv.schedule.dateStart + '"]')
                    dayElemEnd = document.querySelector('[data-fulldate="' + adv.schedule.dateEnd + '"]')



                    let monthBeginning = dateYear + '-' + dateMonth + '-01'
                    let monthEnding = dateYear + '-' + dateMonth + '-' + daysInMonth



                    //se inizia prima del mese attivo
                    if ((!dayElemStart) && (startRef < dateRef)) {
                        dayElemStart = document.querySelector('[data-fulldate="' + monthBeginning + '"]')
                        adv.startBefore = true
                    }

                    //se finisce dopo il mese attivo
                    if ((!dayElemEnd) && (dateRef < endRef)) {
                        dayElemEnd = document.querySelector('[data-fulldate="' + monthEnding + '"]')
                        adv.endAfter = true
                    }

                    // console.log(monthBeginning, dayElemStart)
                    // console.log(monthEnding, dayElemStart)


                    adv.left = dayElemStart.offsetLeft
                    adv.width = dayElemEnd.offsetLeft + dayElemEnd.offsetWidth - dayElemStart.offsetLeft

                }



            });

            // console.log(this.calendarAds)
        }


    }
}

</script>
