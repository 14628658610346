<template>

    <h1>test</h1>

</template>


<script>

export default {
    name: 'Test',

    data() {
        return {

        }
    }
}

</script>
