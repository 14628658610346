<template>

    <div v-if="!currentUser.uid" class="login_page">

        <div class="login_right">

            <img src="img/bkg.jpg" alt="">

        </div>

        <div class="Login_left">

            <div class="login_form">

                <img class="login_logo" src="img/easyad-logo-blue.svg" alt="">

                <h2>Access</h2>

                <p>Email:</p>
                <span class="error" v-if="error.mail">{{error.mail}}</span>

                <input class="login_field" type="text" name="" @keyup.enter="login()" v-model="loginUser.email">



                <p>Password:</p>
                <span class="error" v-if="error.password">{{error.password}}</span>

                <input class="login_field" type="password" @keyup.enter="login()" name="" v-model="loginUser.password">



                <div class="button main" @click="login()">
                    <span>Login</span>
                </div>

            </div>

        </div>

    </div>


    <div v-if="currentUser.uid" class="login_form logged">

        <h3>User logged: {{currentUser.email}}</h3>

        <div class="button main" @click="logout()">
            <span>Logout</span>
        </div>

    </div>

</template>



<script>
import { db, storage } from "../services/firebaseDb.js";
import { getAuth, signOut, setPersistence, browserSessionPersistence, signInWithEmailAndPassword, } from "firebase/auth";

const auth = getAuth();

export default {
    name: 'LoginForm',
    data() {
        return {
            loginUser: {
                email: '',
                password: ''
            },
            error: {},
            user: auth.currentUser
        }
    },

    computed: {
        currentUser() {
            return this.$store.state.user
        }
    },

    methods: {

        login() {
            this.error.mail = null
            this.error.password = null

            signInWithEmailAndPassword( auth, this.loginUser.email, this.loginUser.password )
            .then( cred => {
                this.$store.state.user = cred.user
                this.$router.push('/home')
            })
            .catch((error) => {

                console.log(error.code)

                if(error.code.indexOf('invalid-email')){
                    this.error.mail = 'Email Errata'
                };
                if(error.code.indexOf('wrong-password')){
                    this.error.password = 'Password Errata'
                };
            });
        },

        enterKey(event) {

            if (event.keyCode === 13) {
                this.login()
            }
        },

        logout() {
            signOut(auth)
            .then(() => {

                this.$store.state.user = {}

            }).catch((error) => {
                const errorCode = error.code;
                const errorMessage = error.message;
            });
        }

    }
}


</script>
