<template>

    <h1>Script</h1>

    <p>Place this code right before the &lt;/body&gt; tag in your web page or template.</p>

    <pre>
        <code>&lt;script id="easyad" src="https://embed.easyad.app/ad.js?id=<strong>5qrPs8G67NZX3LbevX5imnQbCwk1</strong>"&gt;&lt;/script&gt;</code>
    </pre>

</template>

<script>

export default {
    name: 'ScriptComp',
}

</script>
