<script setup>
import PreviewSpace from '@/components/PreviewSpace.vue'
</script>

<template>

    <div class="report_list">

        <div class="adv_row_report header">

            <div class="adv_info name">
            </div>


            <div class="adv_info head_item">
                <span class="info_content">Views</span>
            </div>

            <div class="adv_info head_item">
                <span class="info_content">Unique Views</span>
            </div>

            <div class="adv_info head_item">
                <span class="info_content">Clicks</span>
            </div>

            <div class="adv_info head_item">
                <span class="info_content">Unique Clicks</span>
            </div>

            <div class="adv_info head_item">
                <span class="info_content">CTR</span>
            </div>

            <div class="adv_info head_item">
                <span class="info_content">uCTR</span>
            </div>


            <div class="adv_info head_item">
                <span class="info_content">Start</span>
            </div>

            <div class="adv_info head_item">
                <span class="info_content">End</span>
            </div>

            <div class="adv_info head_item">
                <span class="info_content">Status</span>
            </div>

        </div>


        <div v-for="adv in ads" :key="adv.id" class="adv_row_report">

            <div class="adv_info name">
                <span class="info_content title">{{adv.name}}</span>
            </div>


            <div class="adv_info stats">
                <span class="info_content">{{adv.views}}</span>
            </div>

            <div class="adv_info stats">
                <span class="info_content">{{adv.uniqueViews}}</span>
            </div>

            <div class="adv_info stats">
                <span class="info_content">{{adv.clicks}}</span>
            </div>

            <div class="adv_info stats">
                <span class="info_content">{{adv.uniqueClicks}}</span>
            </div>

            <div class="adv_info stats">
                <span class="info_content">{{ (adv.views != 0) ? (adv.clicks / adv.views).toFixed(2) : '0' }}%</span>
            </div>

            <div class="adv_info stats">
                <span class="info_content">{{ (adv.uniqueViews != 0) ? (adv.uniqueClicks / adv.uniqueViews).toFixed(2) : '0' }}%</span>
            </div>


            <div class="adv_info stats">
                <span class="info_content">{{formatDate(adv.schedule.dateStart)}}</span>
            </div>

            <div class="adv_info stats">
                <span class="info_content">{{formatDate(adv.schedule.dateStart)}}</span>
            </div>

            <div class="adv_info stats">
                <span class="info_content" :class="{active: adv.active}">
                    {{adv.active ? 'Active' : 'Inactive'}}
                </span>
            </div>

        </div>
        
    </div>



</template>


<script>

import { db, storage } from '@/services/firebaseDb.js';
import { query, where, collection, getDoc, getDocs, addDoc, doc, setDoc, onSnapshot, deleteDoc, updateDoc} from 'firebase/firestore';
import {ref, deleteObject, uploadBytesResumable, getDownloadURL} from 'firebase/storage';


export default {
    name: 'AdsList',

    data() {
        return {
            ads: [],
        }
    },


    mounted() {
        setTimeout(()=>{
            this.loadData().then(()=>{
                this.loadStats()
            })
        }, 500)
    },

    methods: {
        formatDate(value) {
            let y = value.split('-')[0]
            let m = value.split('-')[1]
            let d = value.split('-')[2]

            return d + ' ' + this.$store.state.months[Number(m)-1] + ' ' + y.slice(-2)
        },

        changeStatus(value) {
            if(value == 'new') {
                if (!this.newAdv.active) {
                    this.newAdv.active = true
                } else {
                    this.newAdv.active = !this.newAdv.active
                }
            }
            if(value == 'edit') {
                if (!this.editAdv.active) {
                    this.editAdv.active = true
                } else {
                    this.editAdv.active = !this.editAdv.active
                }
            }
        },

        loadStats() {

            const adsUpdate = () => {
                let updatesAds = this.ads.map( async (adv) => {

                    console.log(adv)

                    let analRef = doc(db, 'analytics/' + adv.id)
                    const anal = await getDoc(analRef);

                    console.log(anal.data())

                    adv.views = anal.data().views ? anal.data().views : 0
                    adv.uniqueViews = anal.data().uniqueViews ? anal.data().uniqueViews : 0

                    adv.clicks = anal.data().clicks ? anal.data().clicks : 0
                    adv.uniqueClicks = anal.data().uniqueClicks ? anal.data().uniqueClicks : 0
                });
            }

            const q = query(collection(db, "analytics"));
            const unsubscribe = onSnapshot(q, (snapshot) => {
                snapshot.docChanges().forEach((change) => {
                    
                    adsUpdate()

                });

            });
        },

        async loadData() {
            this.ads = []

            let uid = this.$store.state.user.uid
            let adsRef = collection(db, 'companies/' + uid + '/ads')

            const querySnapshot = await getDocs(adsRef)

            querySnapshot.forEach(item => {

                let adv = {...item.data()}
                adv.id = item.id

                if(!this.ads.some(ad => ad.id == adv.id)) {
                    this.ads.push(adv)
                }

            });

        },

    }
}

</script>
