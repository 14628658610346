<template>

  <div class="main_content home">

    <AdsList/>

  </div>

</template>

<script>
// @ is an alias to /src
import AdsList from '@/components/AdsList.vue'

export default {
  name: 'Ads',
  components: {
    AdsList
  }
}
</script>
