<script setup>
import CalendarComp from '@/components/CalendarComp.vue'
</script>

<template>

    <CalendarComp/>

</template>

<script>
    export default {
        name: 'Calendar'
    }
</script>
