import { initializeApp } from "firebase/app"
import { getFirestore } from "firebase/firestore"
import { getStorage } from "firebase/storage"
import { getAnalytics } from "firebase/analytics";


const firebaseApp = initializeApp({
    apiKey: "AIzaSyBTYZRsPbkjGwJehaS2IQTeT0QwC8rnffE",
    authDomain: "the-adv-manager.firebaseapp.com",
    projectId: "the-adv-manager",
    storageBucket: "the-adv-manager.appspot.com",
    messagingSenderId: "1074137933472",
    appId: "1:1074137933472:web:fdfa518b21c37f5a4dcbc5"
});


const db = getFirestore();

const storage = getStorage(firebaseApp);

const analytics = getAnalytics(firebaseApp);


export { firebaseApp, db, storage }
