<template>

    <div class="preview_space">

        <div class="device">

            <div class="preview_layer">

                <div class="ad_overlay"
                    :style="'background: ' + previewstyle.backgroundColor">

                    <div class="ad_header">
                        <span class="ad_title"
                            :style="'color: ' + previewstyle.textColor">EasyAd.app</span>
                    </div>

                    <div class="ad_main">
                        <div class="ad_mainWrapper">

                            <img class="ad_video"
                                v-if="imageurl && !videourl"
                                :src="imageurl">

                            <video class="ad_video" muted="muted" autoplay  loop playsinline
                                v-if="videourl"
                                :poster="imageurl">

                                <source
                                    v-if="videourl"
                                    :src="videourl"
                                    type="video/mp4">

                            </video>

                            <a class="ad_button"
                                v-if="adv.textButton && adv.urlLink"
                                :style="'background: ' + previewstyle.buttonColor"
                                :href="adv.urlLink">

                                <span :style="'color: ' + previewstyle.buttonText">{{adv.textButton}}</span>

                            </a>

                        </div>
                    </div>

                    <div class="ad_bottom">
                        <span class="ad_bottomText"
                            :style="'color: ' + previewstyle.textColor">Skip adv in {{adv.skipSeconds}}s</span>

                        <!-- <div class="ad_bottomButton">
                            <span>Skip messaggio</span>
                        </div> -->
                    </div>

                </div>

            </div>

            <div class="notch">
                <div class="camera">
                </div>

                <div class="speaker">
                </div>
            </div>

        </div>

    </div>

</template>



<script>

export default {
    name: 'PreviewSpace',
    props: ['adv', 'imageurl', 'videourl', 'previewstyle']
}

</script>
