<template>

    <div class="navbar">

        <div class="logobox">
            <img src="img/easyad-logo-white.svg" alt="">
        </div>

        <div class="nav_head">
            <span>{{pagename}}</span>
        </div>

        <div class="userbox">

            <router-link to="/account" class="button">
                 <i class="ri-account-circle-line"></i>
                <span>{{username}}</span>
            </router-link>

        </div>

    </div>

</template>

<script>

export default {
    name: 'NavBar',
    data() {
        return {

        }
    },
    computed: {
        username() {
            return this.$store.state.user.email
        },
        pagename() {
            return this.$route.name
        }
    }
}

</script>
