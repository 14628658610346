import { createRouter, createWebHistory } from 'vue-router'
import Home from '../views/Home.vue'
import Login from '../views/Login.vue'
import Account from '../views/Account.vue'
import SuperAdmin from '../views/SuperAdmin.vue'
import Test from '../views/Test.vue'
import Ads from '../views/Ads.vue'
import Calendar from '../views/Calendar.vue'
import Script from '../views/Script.vue'
import Reports from '../views/Reports.vue'

const routes = [
    {
        path: '/home',
        name: 'Home',
        component: Home
    },
    {
        path: '/ads',
        name: 'Ads',
        component: Ads
    },
    {
        path: '/account',
        name: 'Account',
        component: Account
    },
    {
        path: '/script',
        name: 'Script',
        component: Script
    },
    {
        path: '/reports',
        name: 'Reports',
        component: Reports
    },
    {
        path: '/calendar',
        name: 'Calendario',
        component: Calendar
    },
    {
        path: '/',
        name: 'Login',
        component: Login
    },
    {
        path: '/superadmin',
        name: 'SuperAdmin',
        component: SuperAdmin
    },
    {
        path: '/test',
        name: 'Test',
        component: Test
    }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
