<script setup>
    import { db, storage } from "./services/firebaseDb.js";
    import { getAuth, onAuthStateChanged } from "firebase/auth";
</script>

<template>

    <div class="layout">

        <NavBar v-if="loggedUser"/>

        <SideBar v-if="loggedUser"/>

        <main>
            <router-view/>
        </main>

    </div>



</template>

<script>
import NavBar from '@/components/NavBar.vue'
import SideBar from '@/components/SideBar.vue'

const auth = getAuth();

export default {
    data() {
        return{

        }
    },
    created() {
        onAuthStateChanged(auth, (user) => {
            if (user.uid) {
                this.$store.commit('loginUser', user)
                this.$route.path = '/home'
            } else {

                if (this.$route.path != '/') {
                    this.$route.path = '/'
                }

            }
        });
    },
    computed: {
        loggedUser() {
            if (this.$store.state.user.uid) {
                return true
            }
            else {
                return false
            }

        }
    }
}

</script>



<style lang="scss">
@import './scss/style.scss'
</style>
