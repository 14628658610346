<template>

  <div class="home">

    <div class="home_grid">

        <router-link class="home_box"
            :to="'/ads'">

            <div class="box_img">
                <img src="/img/1.jpg" alt="">
            </div>

            <div class="box_bottom">
                <span>Create and edit your ads</span>
                 <i class="ri-arrow-right-line"></i>
            </div>
        </router-link>

        <router-link class="home_box"
            :to="'/calendar'">

            <div class="box_img">
                <img src="/img/2.jpg" alt="">
            </div>

            <div class="box_bottom">
                <span>Check your publishing calendar</span>
                 <i class="ri-arrow-right-line"></i>
            </div>
        </router-link>

        <router-link class="home_box"
            :to="'/script'">

            <div class="box_img">
                <img src="/img/6.jpg" alt="">
            </div>

            <div class="box_bottom">
                <span>Code snippet for your website</span>
                 <i class="ri-arrow-right-line"></i>
            </div>
        </router-link>

        <!-- <router-link class="home_box">

            <div class="box_img">
                <img src="/img/4.jpg" alt="">
            </div>

            <div class="box_bottom">
                <span>Anteprima e test</span>
                 <i class="ri-arrow-right-line"></i>
            </div>
        </router-link> -->

        <router-link class="home_box"
            :to="'/reports'">

            <div class="box_img">
                <img src="/img/3.jpg" alt="">
            </div>

            <div class="box_bottom">
                <span>Analytics & insights</span>
                 <i class="ri-arrow-right-line"></i>
            </div>
        </router-link>

        <router-link class="home_box"
            :to="'/reports'">

            <div class="box_img">
                <img src="/img/5.jpg" alt="">
            </div>

            <div class="box_bottom">
                <span>Account settings</span>
                 <i class="ri-arrow-right-line"></i>
            </div>
        </router-link>



    </div>

  </div>

</template>

<script>

export default {
  name: 'Home'
}

</script>
