<script setup>
import ScriptComp from '@/components/ScriptComp.vue'
</script>

<template>

    <ScriptComp/>

</template>

<script>

    export default {
        name: 'Script',
    }

</script>
